import axios from "axios";
export const weeklyPlanMixin = {
  props: ["classId", "academicId", "teacherId"],
  data() {
    return {};
  },
  methods: {
    saveNotes() {
      this.loading = true;

      axios
        .post(this.getApiUrl + "/" + "weekly_plans/store/", this.editedItem, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status.error == false) {
            this.close();
            this.getNotes();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveDayNotes() {
      this.loading = true;
      axios
        .post(
          this.getApiUrl + "/" + "weekly_plans/day_notes/store",
          this.editedItemDay,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((res) => {
          this.loading = false;
          if (res.data.status.error == false) {
            this.closeDayNotesDialog();
            this.getNotes();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelEdit() {
      this.editMode = false;
      this.getNotes();
    },

    getNotes() {
      var optional = "";
      if (this.selectedWeekId) {
        optional = "/" + this.selectedWeekId;
      }
      if (!this.academicId && !this.classId) {
        axios
          .get(
            this.getApiUrl +
              "/teachers/weekly_plan/" +
              this.teacherId +
              optional +
              "?class_id=" +
              this.selectedClass,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((res) => {
            this.schedules = res.data.data.week_plans.schedules;
            this.scheduleLessonsNames =
              res.data.data.week_plans.scheduleLessonsNames;
            this.schedulesFromTo = res.data.data.week_plans.schedulesFromTo;
            this.weeks = res.data.data.weekIdsNames;
            this.weekDates = res.data.data.weekDates;
            if (this.selectedWeekId == "" && this.weeks.length > 0) {
              this.selectedWeekId = res.data.data.week_id;
              this.selectedWeekFrom = this.weekDates[this.selectedWeekId].from;
              this.selectedWeekTo = this.weekDates[this.selectedWeekId].to;
            }
          });
      } else {
        axios
          .get(
            this.getApiUrl +
              "/" +
              "weekly_plans/get/" +
              this.academicId +
              "/" +
              this.classId +
              optional,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((res) => {
            if (res.data.status.error == false) {
              this.schedules = res.data.data.week_plans.schedules;
              this.scheduleLessonsNames =
                res.data.data.week_plans.scheduleLessonsNames;
              this.schedulesFromTo = res.data.data.week_plans.schedulesFromTo;
              this.weeks = res.data.data.weekIdsNames;
              this.weekDates = res.data.data.weekDates;
              if (this.selectedWeekId == "" && this.weeks.length > 0) {
                this.selectedWeekId = this.weeks[0].id;
                this.selectedWeekFrom =
                  this.weekDates[this.selectedWeekId].from;
                this.selectedWeekTo = this.weekDates[this.selectedWeekId].to;
              }
              // fill the lessonIds array
              this.schedules.forEach((schedule, day) => {
                this.daysLessonsIds[day] = [];
                schedule.lessons.forEach((lesson) => {
                  if (lesson.type == "lesson") {
                    if (
                      lesson.lesson_id == undefined ||
                      lesson.subject_id == null ||
                      lesson.teacher == null
                    ) {
                      lesson.lesson_id = null;
                    }
                    this.daysLessonsIds[day].push(lesson.lesson_id);
                  }
                });
              });
              this.weekStartDate = res.data.data.week_plans.week_start;
              this.weekEndDate = res.data.data.week_plans.week_end;
              console.log("this.daysLessonsIds", this.daysLessonsIds);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    changeStatus() {
      this.loading = true;
      axios
        .get(
          this.getApiUrl +
            "/" +
            "weekly_plans/change_status/" +
            this.classId +
            "?academic_week_id=" +
            this.selectedWeekId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((res) => {
          this.loading = false;
          console.log(res);
          this.getNotes();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getNotes();
  },
};
